import { IMultiLangLabel } from "../shared/multi-language";
import { getLogger, Logger } from "./LoggingService";

export interface IJurisdiction {
    id: string;

    // override fields
    logo: string;
    welcome: IMultiLangLabel;
    opening?: IMultiLangLabel;
    age: boolean;
    dob: boolean;
    livingSituation: boolean;
    rural: boolean;
    healthcare: boolean;
    frontlineWorker: boolean;
    essentialWorker: boolean;
    conditions: boolean;
    phone: boolean;

    attestation: IMultiLangLabel;

    enableAuthRep: boolean;
    authRepAttestation: IMultiLangLabel;

    ineligibleClosing: IMultiLangLabel;
    eligibleClosing: IMultiLangLabel;
    existingRecordFound: IMultiLangLabel;
    existingUpdatedEligible: IMultiLangLabel;
    existingUpdatedIneligible: IMultiLangLabel;
    exportedClosing: IMultiLangLabel;

    languageEnabled: boolean;
    languageTooltip: IMultiLangLabel;

    redirectToGuest?: boolean
}

export default class ConfigurationService {
    private logger: Logger;

    // These properties are assigned from config.json
    environment: string;
    region: string;
    apiUrl: string;
    buildTime: number;
    version: string;

    guestLandingUrl: string;

    // Disabled captcha used for local development
    captchaEnabled: string;

    default: IJurisdiction;
    jurisdictions: {
        [name: string]: IJurisdiction;
    };

    constructor() {
        this.logger = getLogger("ConfigurationService");
    }

    public async load() {
        const response = await fetch("config.json");
        const cfg = await response.json();
        Object.assign(this, cfg);
        this.logger.debug("Config Loaded");
    }

    get loginUrl(): string {
        return window.location.origin;
    }

    getJurisdiction(name: string): IJurisdiction {
        if (!this.jurisdictions[name]) {
            return null;
        }
        return Object.assign({ ...this.default }, this.jurisdictions[name], { id: name });
    }
}
