import React, { Component } from "react";
import AppContext, { IAppContext, JurisdictionLabelId } from "./AppContext";
import ConfigurationService from "../services/ConfigurationService";
import { ApiClient } from "../services/ApiClient";
import { getLogger } from "../services/LoggingService";
import qs from "qs";
import { Grid, Message } from "semantic-ui-react";
import { GetLabelForLanguage, getQuestionForLanguage, IQuestionData, LabelId, LanguageId, QuestionId } from "../shared/multi-language";

interface IState {
    status: "Loading" | "Ready";
    error: string;
    context: IAppContext;
}
export class AppContextProvider extends Component<{}, IState> {
    logger = getLogger("AppContextProvider");
    cfg: ConfigurationService;

    constructor(props: any) {
        super(props);

        this.cfg = new ConfigurationService();

        this.state = {
            status: "Loading",
            error: "",
            context: {
                config: null,
                jurisdiction: null,
                api: null,
                lang: null,
                getQuestion: null,
                getLabel: null,
                getJurisdictionLabel: null,
            },
        };
    }

    async componentDidMount() {
        try {
            const qsParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
            const jurisdiction = qsParams.jurisdiction as string;
            if (!jurisdiction) {
                this.setState({ error: 'The "jurisdiction" parameter is required in the address bar.' });
                return;
            }

            const lang = qsParams.lang as LanguageId || "en";

            await this.cfg.load();
            const jurisdictionConfig = this.cfg.getJurisdiction(jurisdiction);

            if (!jurisdictionConfig) {
                this.setState({
                    error: `Unable to locate configuration for ${jurisdiction}. Your jurisdiction is not eligible for VAMS vaccination.`,
                });
                return;
            }

            const repo = new ApiClient(this.cfg);

            this.setState({
                ...this.state,
                status: "Ready",
                context: {
                    config: this.cfg,
                    jurisdiction: jurisdictionConfig,
                    api: repo,
                    lang: lang as any,
                    getQuestion: (id: QuestionId) => getQuestionForLanguage(id, this.state.context.lang),
                    getLabel: (id: LabelId) => GetLabelForLanguage(id, this.state.context.lang),
                    getJurisdictionLabel: (id: JurisdictionLabelId) => (this.state.context.jurisdiction[id][this.state.context.lang]),
                },
            });

            document.title = "Vaccine Eligibility";
        } catch (error) {
            this.logger.error(`Configuration Failed: ${error.message}`, error);
        }
    }

    render() {
        if (this.state.error) {
            return (
                <Grid padded container fluid>
                    <Grid.Row>
                        <Grid.Column>
                            <Message error>
                                <Message.Header>Error</Message.Header>
                                <p>{this.state.error}</p>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }
        if (!this.state.context.config) {
            return (
                <div>
                    <p>{this.state.status}</p>
                </div>
            );
        }
        return <AppContext.Provider value={this.state.context}>{this.props.children}</AppContext.Provider>;
    }
}
