import React, { useState } from "react";
import { CheckboxProps, Dropdown, DropdownProps, Form, InputOnChangeData, Message, Segment } from "semantic-ui-react";
import { AppLabel, ILabelProps } from "./AppLabel";
import DatePicker from "react-datepicker";
import moment from "moment";
import { IOption } from "../../shared/multi-language";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale('es', es)
setDefaultLocale('en');

/**
 * Dont update Formik until user leaves field
 */

interface IFieldProps extends ILabelProps {
  name: string;
  disabled?: boolean;
  value: any;
  onChanged: (val: any) => void;
  error: string;
  required?: boolean;
  locale?: string;
}

interface IOptionProps extends IFieldProps {
  options: IOption[];
  hideBorder?: boolean;
}

export function AppTextbox(props: IFieldProps) {
  const handleChange = (e: any, data: InputOnChangeData) => {
    props.onChanged(data.value);
  };

  return (
    <Form.Input
      aria-label={props.label}
      label={props.label}
      name={props.name}
      onChange={handleChange}
      value={props.value}
      disabled={props.disabled}
      error={props.error && { content: props.error }}
      required={props.required}
    />
  );
};

export function AppDate (props: IFieldProps){
  const [minDate] = useState(moment().add(-125, "years").toDate());
  const [maxDate] = useState(moment().add(-16, "years").toDate());

  const handleChange = (d: Date) => {
    props.onChanged(d);
  };

  return (
    <Form.Field>
      <AppLabel
        label={props.label}
        subLabel={props.subLabel}
        subLabelLink={props.subLabelLink}
        required={props.required}
        id={props.name + "-label"}
      />
      <Message error visible={props.error ? true : false}>
        {props.error}
      </Message>
      <DatePicker
        locale={props.locale || 'en'}
        aria-label={props.label}
        ariaLabelledBy={props.name + "-label"}
        selected={props.value}
        onChange={handleChange}
        minDate={minDate}
        maxDate={maxDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        strictParsing
        dateFormat="M/d/yyyy"
      />
    </Form.Field>
  );
};

export function AppDropdown(props: IOptionProps) {

  const handleChange = (e: any, data: DropdownProps) => {
    props.onChanged(data.value);
  };

  return (
    <Form.Field>
      <AppLabel
        label={props.label}
        subLabel={props.subLabel}
        subLabelLink={props.subLabelLink}
        required={props.required}
      />
      <Message error visible={props.error ? true : false}>
        {props.error}
      </Message>
      <Dropdown
        aria-label={props.label}
        search
        selection
        value={props.value}
        options={props.options}
        onChange={handleChange}
        disabled={props.disabled}
      />
    </Form.Field>
  );
};

export function AppRadioGroup(props: IOptionProps) {
  const handleClick = (newVal: string) => {
    if (props.disabled) {
      return;
    }
    props.onChanged(props.value === newVal ? "" : newVal);
  };

  const renderOption = (option: IOption) => {
    const key = `${props.name}-${option.value}`;
    return (
      <Form.Radio
        aria-label={option.text}
        key={key}
        label={option.text}
        name={props.name}
        value={props.value}
        checked={props.value === option.value}
        onClick={() => handleClick(option.value as string)}
        disabled={props.disabled}
      />
    );
  };

  const renderQuestion = () => (
    <Form.Group grouped>
      <AppLabel
        label={props.label}
        subLabel={props.subLabel}
        subLabelLink={props.subLabelLink}
        required={props.required}
      />
      <Message error visible={props.error ? true : false}>
        {props.error}
      </Message>
      {props.options.map(renderOption)}
    </Form.Group>
  );

  if (props.hideBorder) {
    return renderQuestion();
  }
  return <Segment>{renderQuestion()}</Segment>;
};

export function AppCheckboxGroup(props: IOptionProps){

  const handleClick = (e: any, data: CheckboxProps) => {
    if (props.disabled) {
      return;
    }
    const option = data.value.toString();


    if (data.checked) {
      if (!props.value.includes(option)) {
        props.onChanged([...props.value, option]);
      }
    } else {
      const index = props.value.indexOf(option);
      if (index > -1) {
        const copy = [...props.value];
        copy.splice(index, 1);
        props.onChanged([...copy]);
      }
    }
  };

  const renderOption = (option: IOption) => {
    const key = `${props.name}-${option.value}`;
    return (
      <Form.Checkbox
        aria-label={option.text}
        key={key}
        label={<label dangerouslySetInnerHTML={{ __html: option.text }} />}
        name={props.name}
        value={option.value as string}
        checked={props.value.includes(option.value as string)}
        onClick={handleClick}
        disabled={props.disabled}
      />
    );
  };

  return (
    <Segment>
      <Form.Field>
        <AppLabel
          label={props.label}
          subLabel={props.subLabel}
          subLabelLink={props.subLabelLink}
          required={props.required}
        />
        <Message error visible={props.error ? true : false}>
          {props.error}
        </Message>
        {props.options.map(renderOption)}
      </Form.Field>
    </Segment>
  );
};
