import React from "react"

export interface ILabelProps {
  label: string,
  subLabel?: string,
  subLabelLink?: string,
  required?: boolean,
  className?: string,
  id?: string;
}
export function AppLabel(props: ILabelProps) {

  const subLabel = (props.subLabel && props.subLabelLink)
    ? <p><small><a href={props.subLabelLink} target="_blank" rel="noopener noreferrer">{props.subLabel}</a></small></p>
    : props.subLabel
      ? <p><small>{props.subLabel}</small></p>
      : null

  return (
    <>
      <label id={props.id} className={props.className || "app-label"}>{props.required && <span className="required-field">*</span>}{props.label}
        {subLabel}
      </label>
    </>);
}