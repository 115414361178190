import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { Button, Form, Grid, Message, Segment } from "semantic-ui-react";
import { EligibilityFormData, EligibilityFormDataSchema } from "../shared/api-interfaces";
import AppContext from "../contexts/AppContext";
import {
    AgeQuestion,
    LivingSituationQuestion,
    FrontlineWorkerQuestion,
    EssentialWorkerQuestion,
    ConditionsQuestion,
    HealthcareQuestion,
    TextInputQuestion,
    RuralQuestion,
    DateOfBirthQuestion,
    EmailQuestion,
    PreferredContactMethod,
    AuthorizedRepresentativeQuestion,
} from "./base/Questions";
import * as Yup from "yup";
import ReactMarkdown from "react-markdown"
import { AppLabel } from "./base/AppLabel";

export function EligibilityForm(props: any) {
    const [disabled, setDisabled] = useState(false);
    const [infoIsCorrect, setInfoIsCorrect] = useState(false);

    const [message, setMessage] = useState<{
        header: string;
        body: string;
        confirmation?: string;
        showOverwrite?: boolean;
        error?: boolean;
        success?: boolean;
        warning?: boolean;
    }>(null);

    const ctx = useContext(AppContext);
    const [schema] = useState(EligibilityFormDataSchema(ctx.lang));

    if (ctx.jurisdiction.dob && !ctx.jurisdiction.age) {
        // Make field required
        schema.fields.dob = Yup.string().required(ctx.getLabel("dobRequired"));
    }

    const onSubmit = async (values: EligibilityFormData) => {
        try {
            setDisabled(true);
            setMessage(null);

            if (ctx.config.captchaEnabled === "true") {
                let token: string = await (window as any).getCaptchaToken();
                values.captchaVerificationCode = token;
                console.log(values);
            } else {
                console.log("Captcha disabled");
            }

            if (values.preferredContactMethod === "sms" && !values.otpId) {
                const id = await ctx.api.sendOtp(values);
                formik.setFieldValue("otpId", id);
                setDisabled(false);
                return;
            }

            const response = await ctx.api.submit(values);
            if (response.status === "ineligible") {
                if (formik.values.overwrite === true) {
                    setMessage({ success: true, header: ctx.getLabel("success"), body: ctx.getJurisdictionLabel("existingUpdatedIneligible"), confirmation: response.confirmation });
                } else {
                    setMessage({ success: true, header: ctx.getLabel("success"), body: ctx.getJurisdictionLabel("ineligibleClosing"), confirmation: response.confirmation });
                }

            } else if (response.status === "eligible") {
                if (formik.values.overwrite === true) {
                    setMessage({ success: true, header: ctx.getLabel("eligible"), body: ctx.getJurisdictionLabel("existingUpdatedEligible"), confirmation: response.confirmation });
                }
                else {
                    setMessage({ success: true, header: ctx.getLabel("eligible"), body: ctx.getJurisdictionLabel("eligibleClosing"), confirmation: response.confirmation });
                }
            } else if (response.status === "exists") {
                setMessage({ warning: true, header: ctx.getLabel("existingRecordFound"), body: ctx.getJurisdictionLabel("existingRecordFound"), showOverwrite: true });
            } else if (response.status === "exported") {
                setMessage({ error: true, header: ctx.getLabel("exported"), body: ctx.getJurisdictionLabel("exportedClosing") });
            }

        } catch (error) {
            console.log(error);
            setMessage({ error: true, header: ctx.getLabel("error"), body: error.message });
            setDisabled(false);
        }
    };

    const defaultValues = (): EligibilityFormData => {
        return {
            timestamp: "",
            jurisdiction: ctx.jurisdiction.id,
            preferredLanguage: ctx.lang,
            age: "",
            livingSituation: "",
            rural: "",
            healthcare: "",
            frontlineWorker: "",
            essentialWorker: "",
            conditions: [],
            firstName: "",
            lastName: "",
            zip: "",
            email: "",
            dob: "",
            phone: "",
            captchaVerificationCode: "",
            preferredContactMethod: "email",
            isAuthRep: "false",
        };
    };

    const formik = useFormik({
        initialValues: defaultValues(),
        validationSchema: schema,
        onSubmit: onSubmit,
    });

    const submitDisabled = (): boolean => {
        return disabled
            || !infoIsCorrect
            || (formik.values.preferredContactMethod === "sms" && !formik.values.otpCode);
    }

    const showSmsVerification = (): boolean => {
        return formik.values.preferredContactMethod === "sms" && formik.values.otpId ? true : false;
    }

    const opening = ctx.getJurisdictionLabel("opening");

    if (ctx.jurisdiction.redirectToGuest && ctx.config.guestLandingUrl) {
        window.location.href = ctx.config.guestLandingUrl + '?jurisdiction=' + ctx.jurisdiction.id

        return (
            <h1>Redirecting to Guest Registration</h1>
        )
    }

    return (
        <Form success className="eligibility-form" onSubmit={formik.handleSubmit}>
            <Message style={{
                background: "#022748",
                color: "white"
            }}>
                <ReactMarkdown>{ctx.getJurisdictionLabel("welcome")}</ReactMarkdown>
                {opening && <ReactMarkdown linkTarget="_blank">{opening}</ReactMarkdown>}
            </Message>
            <p className="required-field-legend"><span className="color-red">*</span> {ctx.getLabel("requiredLegend")}.</p>
            {ctx.jurisdiction.dob && <DateOfBirthQuestion formik={formik as any} disabled={disabled} required={ctx.jurisdiction.dob && !ctx.jurisdiction.age} />}
            {(ctx.jurisdiction.age || !ctx.jurisdiction.dob) && <AgeQuestion formik={formik as any} disabled={disabled} />}
            {ctx.jurisdiction.livingSituation && <LivingSituationQuestion formik={formik as any} disabled={disabled} />}
            {ctx.jurisdiction.rural && <RuralQuestion formik={formik as any} disabled={disabled} />}
            {ctx.jurisdiction.healthcare && <HealthcareQuestion formik={formik as any} disabled={disabled} />}
            {ctx.jurisdiction.frontlineWorker && <FrontlineWorkerQuestion formik={formik as any} disabled={disabled} />}
            {ctx.jurisdiction.essentialWorker && <EssentialWorkerQuestion formik={formik as any} disabled={disabled} />}
            {ctx.jurisdiction.conditions && <ConditionsQuestion formik={formik as any} disabled={disabled} />}
            <Segment>
                <h2>{ctx.getLabel("recipientInformation")}</h2>
                <Form.Group widths={"equal"}>
                    <TextInputQuestion
                        name="firstName"
                        label={ctx.getLabel("firstName")}
                        formik={formik as any}
                        disabled={disabled}
                        required={true}
                    />
                    <TextInputQuestion
                        name="lastName"
                        label={ctx.getLabel("lastName")}
                        formik={formik as any}
                        disabled={disabled}
                        required={true}
                    />
                    <TextInputQuestion
                        name="zip"
                        label={ctx.getLabel("zip")}
                        formik={formik as any}
                        disabled={disabled}
                        required={true}
                    />
                </Form.Group>
            </Segment>
            <Segment>
                <h2>{ctx.getLabel("contactInformation")}</h2>
                <p>{ctx.getLabel("contactInformationDescription")}</p>
                <PreferredContactMethod formik={formik as any} disabled={disabled} />
                <Form.Group >
                    {/* formik.values.preferredContactMethod === "sms" */}
                    <EmailQuestion formik={formik as any} disabled={disabled} />
                </Form.Group>
                <Form.Group>
                    <TextInputQuestion
                        name="phone"
                        label={ctx.getLabel("phone")}
                        formik={formik as any}
                        disabled={disabled}
                        width={"8"}
                        required={formik.values.preferredContactMethod === "sms"}
                    />
                </Form.Group>
                {ctx.jurisdiction.enableAuthRep && <AuthorizedRepresentativeQuestion formik={formik as any} disabled={disabled} />}
                {showSmsVerification() && <Grid>
                    <Grid.Column width={"16"}>
                        <AppLabel label={ctx.getLabel("otpVerification")} subLabel={ctx.getLabel("otpDescription")} className="app-label" required={true} />
                    </Grid.Column>
                    <Grid.Column width={"5"}>
                        <TextInputQuestion name="otpCode" formik={formik as any} disabled={disabled} required={true} />
                    </Grid.Column>
                    <Grid.Column width={"3"} textAlign={"right"}>
                        <Button disabled={disabled} type="button" onClick={() => { formik.setFieldValue("otpId", ""); formik.setFieldValue("otpCode", ""); formik.submitForm() }}>{ctx.getLabel("resendCode")}</Button>
                    </Grid.Column>
                </Grid>}
                {message && <Message
                    visible={true}
                    error={message.error}
                    success={message.success}
                >
                    <Message.Header>{message.header}</Message.Header>
                    {message.body && <p>{message.body}</p>}
                        {message.confirmation && <p>Id: {message.confirmation}<br /><Button type="button" color={"green"} onClick={() => { window.print() }} >{ctx.getLabel("print")}</Button></p>}
                    {message.showOverwrite && <Button type="button" primary onClick={() => { formik.setFieldValue("overwrite", true); formik.submitForm(); }}>{ctx.getLabel("updateRecord")}</Button>}
                </Message>}
            </Segment>
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column>
                <Form.Checkbox
                    label={
                        formik.values.isAuthRep === "true"
                        ? ctx.getJurisdictionLabel("authRepAttestation")
                        : ctx.getJurisdictionLabel("attestation")
                    }
                    aria-label={
                        formik.values.isAuthRep === "true"
                            ? ctx.getJurisdictionLabel("authRepAttestation")
                            : ctx.getJurisdictionLabel("attestation")
                    }
                    disabled={disabled}
                    checked={infoIsCorrect}
                    onClick={async () => {
                        if (!formik.isValid) {
                            const errorFields = {} as any;
                            Object.keys(formik.errors).forEach(key => errorFields[key] = true);
                            formik.setTouched(errorFields, true)
                            return;
                        }
                        const isCorrect = !infoIsCorrect
                        setInfoIsCorrect(isCorrect);
                        if (isCorrect && formik.values.preferredContactMethod === "sms" && !formik.values.otpId) {
                            formik.submitForm()
                        }
                    }}
                />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div style={{display: "flex"}}>
                                <div>
                                    <Button basic type="button" onClick={() => window.location.reload()}>
                                        {ctx.getLabel("reset")}
                                    </Button>
                                </div>
                                <Form.Group inline>
                                    <Button primary disabled={submitDisabled()}>
                                        {ctx.getLabel("submit")}
                                    </Button>
                                </Form.Group>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
        </Form>
    );
};

